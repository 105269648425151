<template>
  <div class="card">
    <h3 class="card-title">Cargar persona</h3>
    <form @submit.prevent="save">
      <div class="cols2">
        <pd-input
          v-model="persona.nombre"
          id="nombre"
          label="Nombre"
          placeholder="Ej: Apellido Nombre"
          :required="enviado && !persona.nombre"
        ></pd-input>
        <pd-input
          v-model="persona.cuil"
          id="cuil"
          label="CUIL"
          placeholder="Sin guiones"
          inputmode="numeric"
          :required="enviado && !persona.cuil"
        ></pd-input>
        <pd-input
          v-model="persona.email"
          id="email"
          label="Email"
          placeholder="ejemplo@adc.net"
        ></pd-input>
        <pd-input
          v-model="persona.telefono_fijo"
          id="telefono"
          label="Teléfono (opcional)"
          placeholder="Solo números"
          inputmode="numeric"
        ></pd-input>

        <div class="form-group">
          <label class="form-label">Celular (sin 0 ni 15)</label>
          <div class="input-group">
            <div class="input-group input-group-prepend">
              <div class="input-group-prepend">
                <span class="input-group-text">0</span>
              </div>
              <input
                id="caracteristica"
                placeholder="Ej:2954"
                type="number"
                class="form-control"
                inputmode="numeric"
                v-model="persona.caracteristica_celular"
              />
            </div>
            <div class="input-group input-group-append" style="flex: 2">
              <div class="input-group-prepend">
                <span class="input-group-text">15</span>
              </div>
              <input
                id="numero_celular"
                placeholder="Ej: 555555"
                type="number"
                class="form-control"
                inputmode="numeric"
                v-model="persona.numero_celular"
              />
            </div>
          </div>
        </div>
        <pd-input
          v-model="persona.domicilio"
          id="domicilio"
          label="Domicilio (opcional)"
          placeholder="Domicilio"
        ></pd-input>
        <pd-input
          v-model="persona.localidad"
          id="localidad"
          label="Localidad (opcional)"
          placeholder="Localidad"
        ></pd-input>
        <pd-input
          v-model="persona.provincia"
          id="provincia"
          label="Provincia (opcional)"
          placeholder="Provincia"
        ></pd-input>
        <pd-input
          v-model="persona.codigo_postal"
          id="codigo_postal"
          label="Código postal (opcional)"
          placeholder="Solo números"
          inputmode="numeric"
        ></pd-input>
        <pd-input
          v-model="persona.numero_legajo"
          id="numero_legajo"
          label="N° de legajo"
          placeholder="Solo números"
          inputmode="numeric"
          :required="enviado && !persona.numero_legajo"
        ></pd-input>
        <pd-input
          v-model="persona.fecha_nacimiento"
          id="fecha_nacimiento"
          label="Fecha de nacimiento"
          placeholder="Fecha de nacimiento"
          type="date"
          :required="enviado && !persona.fecha_nacimiento"
        ></pd-input>
        <pd-input
          v-model="persona.fecha_ingreso"
          id="fecha_ingreso"
          label="Fecha de ingreso"
          placeholder="Fecha de ingreso"
          type="date"
          :required="enviado && !persona.fecha_ingreso"
        ></pd-input>
        <suggest
          id="area"
          label="Área"
          placeholder="Buscar área.."
          :data="areas"
          :items="itemsArea"
          @selected="areaSelected"
          :required="enviado && !persona.area_id"
        ></suggest>
        <suggest
          id="sucursal"
          label="Sucursal"
          placeholder="Buscar sucursal.."
          :data="sucursales"
          :items="itemsSucursales"
          @selected="sucursalSelected"
          :required="enviado && !persona.sucursal_id"
        ></suggest>
        <suggest
          id="categoria"
          label="Categoría"
          placeholder="Buscar categoría.."
          :data="categorias"
          :items="itemsCategorias"
          @selected="categoriaSelected"
          :required="enviado && !persona.categoria_id"
        ></suggest>
        <suggest
          id="obra_social"
          label="Obra social"
          placeholder="Buscar obra social.."
          :data="obras_sociales"
          :items="itemsObrasSociales"
          @selected="obraSocialSelected"
          :required="enviado && !persona.obra_social_id"
        ></suggest>
        <pd-input
          v-model="persona.estado"
          id="estado"
          select
          label="Estado"
          placeholder="Seleccione estado"
        >
        <template slot="options">
          <option value="Habilitado">Habilitado</option>
          <option value="Inhabilitado">Inhabilitado</option>
        </template>
      </pd-input>
      </div>
      <button type="submit" class="btn btn-right btn-primary">Guardar</button>
    </form>
  </div>
</template>
<script>
import Suggest from "@/components/elements/Suggest";
import { PersonasService } from "@/modules/personas/services/PersonasServices";
import { AreaServices } from "@/modules/areas/services/AreaServices";
import { SucursalServices } from "@/modules/sucursales/services/SucursalServices";
import { CategoriaServices } from "@/modules/categorias/services/CategoriaServices";
import { ObraSocialServices } from "@/modules/obras_sociales/services/ObraSocialServices";
import provinciasJSON from "@/assets/js/provincias.json";
import localidadesJSON from "@/assets/js/localidades.json";
import Swal from "sweetalert2";

export default {
  name: "NuevaPersona",
  data() {
    return {
      persona: PersonasService.persona,
      descripcion: "",

      area: null,
      areas: null,
      itemsArea: AreaServices.dataList(),

      sucursal: null,
      sucursales: null,
      itemsSucursales: SucursalServices.dataList(),

      categoria: null,
      categorias: null,
      itemsCategorias: CategoriaServices.dataList(),
      
      obra_social: null,
      obras_sociales: null,
      itemsObrasSociales: ObraSocialServices.dataList(),

      provincias: null,
      localidades: null,
      enviado: false,
    };
  },
  components: {
    Suggest,
  },
  mounted() {
    AreaServices.api.getAll().then((areas) => (this.areas = areas));
    SucursalServices.api
      .getAll()
      .then((sucursales) => (this.sucursales = sucursales));
    CategoriaServices.api
      .getAll()
      .then((categorias) => (this.categorias = categorias));
    ObraSocialServices.api
      .getAll()
      .then((obras_sociales) => (this.obras_sociales = obras_sociales));
    this.formatProvinciasJSON();
    this.formatLocalidadesJSON();
  },
  methods: {
    areaSelected(area) {
      this.area = area;
      this.persona.area_id = area.id;
      this.persona.gerencia_id = area.gerencia_id;
    },
    sucursalSelected(sucursal) {
      this.sucursal = sucursal;
      this.persona.sucursal_id = sucursal.id;
    },
    categoriaSelected(categoria) {
      this.categoria = categoria;
      this.persona.categoria_id = categoria.id;
    },
    obraSocialSelected(obra_social) {
      this.obra_social = obra_social;
      this.persona.obra_social_id = obra_social.id;
    },
    save() {
      this.enviado = true;
      PersonasService.api
        .save(this.persona)
        .then((r) => {
          Swal.fire(
            "Éxito",
            `<p><b>${r.persona.nombre}</b> se ha guardado con éxito</p>`,
            "success"
          );
          this.$router.push({ name: "ListarPersonas" });
        })
        .catch((e) => {
          Swal.fire("Error", `<p>${e}</p>`, "error");
        });
    },
    formatProvinciasJSON() {
      let provincias = [];
      provinciasJSON.provincias.forEach((item) => {
        let provincia = {};
        provincia.id = item.id;
        provincia.nombre = item.nombre;
        provincias.push(provincia);
      });
      provincias.sort(function (a, b) {
        if (a.nombre < b.nombre) {
          return -1;
        }
        if (a.nombre > b.nombre) {
          return 1;
        }
        return 0;
      })
      this.provincias = provincias;
    },
    formatLocalidadesJSON() {
      let provincia_id = "42";
      let localidades = [];
      let filter = localidadesJSON.localidades.filter((item) => {
        return item.provincia.id == provincia_id;
      });
      filter.forEach((item) => {
        let localidad = {};
        if (item.localidad_censal) {
          localidad.id = item.localidad_censal.id;
          localidad.nombre = item.localidad_censal.nombre;
        } else {
          console.log("no existe", item);
          localidad.id = item.id;
          localidad.nombre = item.nombre;
        }
        localidades.push(localidad);
      });
      localidades.sort(function (a, b) {
        if (a.nombre < b.nombre) {
          return -1;
        }
        if (a.nombre > b.nombre) {
          return 1;
        }
        return 0;
      });
      this.localidades = localidades;
    },
  },
};
</script>